/* global wpData */
// src/services/socket.js
import { io } from 'socket.io-client';

const socket = io('https://node.masterbhai.com:3000//', {
  transports: ['websocket'],
  query: {
    userId: (typeof wpData !== 'undefined' ? wpData.currentUserId : '1'),
  },
  withCredentials: true,
});

export default socket;
