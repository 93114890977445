/* global wpData */
// src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Assume we have a global wpData object from WordPress
    const user = {
      id: wpData.currentUserId,
      displayName: wpData.currentUserName,
      avatarUrl: wpData.currentUserAvatar,
    };
    setCurrentUser(user);
  }, []);

  return (
    <UserContext.Provider value={{ currentUser }}>
      {children}
    </UserContext.Provider>
  );
}
