// src/components/TypingIndicator.js
import React from 'react';
import styled from 'styled-components';

const TypingContainer = styled.div`
  padding: 5px;
  color: #999;
`;

function TypingIndicator() {
  return <TypingContainer>The other user is typing...</TypingContainer>;
}

export default TypingIndicator;
