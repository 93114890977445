/* global wpData */
// src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: wpData.apiUrl, // Using localized data from WordPress
  headers: {
    'X-WP-Nonce': wpData.nonce, // For authentication
    'Content-Type': 'application/json',
  },
});

export default api;
