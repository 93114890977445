// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConversationList from './components/ConversationList';
import ChatWindow from './components/ChatWindow';
import GlobalStyles from './styles/globalStyles';

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<ConversationList />} />
          <Route path="/chat/:conversationId" element={<ChatWindow />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
