// src/components/MessageList.js
import React, { useRef, useEffect } from 'react';
import MessageItem from './MessageItem';
import styled from 'styled-components';

const ListContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  /* Add styles */
`;

function MessageList({ messages, currentUserId }) {
  const messagesEndRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <ListContainer>
      {messages.map((message) => (
        <MessageItem
          key={message.id}
          message={message}
          isOwnMessage={message.senderId === currentUserId}
        />
      ))}
      <div ref={messagesEndRef} />
    </ListContainer>
  );
}

export default MessageList;
