// src/components/ConversationList.js
import React, { useEffect, useState, useContext } from 'react';
import api from '../services/api';
import ConversationItem from './ConversationItem';
import styled from 'styled-components';
import { UserContext } from '../contexts/UserContext';

const ListContainer = styled.div`
  /* Add styles */
`;

function ConversationList() {
  const [conversations, setConversations] = useState([]);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    async function fetchConversations() {
      try {
        const response = await api.get('/chat/v1/conversations');
        setConversations(response.data);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    }
    fetchConversations();
  }, []);

  return (
    <ListContainer>
      {conversations.map((conversation) => (
        <ConversationItem
          key={conversation.id}
          conversation={conversation}
          currentUserId={currentUser.id}
        />
      ))}
    </ListContainer>
  );
}

export default ConversationList;
