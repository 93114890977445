// src/components/MessageItem.js
import React from 'react';
import styled from 'styled-components';

const MessageBubble = styled.div`
  background-color: ${(props) => (props.isOwnMessage ? '#dcf8c6' : '#fff')};
  align-self: ${(props) => (props.isOwnMessage ? 'flex-end' : 'flex-start')};
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
`;

const MessageContent = styled.p`
  margin: 0;
`;

const MessageTimestamp = styled.small`
  align-self: flex-end;
  color: #999;
`;

function MessageItem({ message, isOwnMessage }) {
  return (
    <MessageBubble isOwnMessage={isOwnMessage}>
      <MessageContent>{message.content}</MessageContent>
      <MessageTimestamp>
        {new Date(message.timestamp).toLocaleTimeString()}
      </MessageTimestamp>
    </MessageBubble>
  );
}

export default MessageItem;
