// src/components/ChatWindow.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import socket from '../services/socket';
import { UserContext } from '../contexts/UserContext';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import TypingIndicator from './TypingIndicator';
import styled from 'styled-components';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

function ChatWindow() {
  const { conversationId } = useParams();
  const { currentUser } = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const response = await api.get(`/chat/v1/conversations/${conversationId}/messages`);
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    }
    fetchMessages();

    socket.emit('joinConversation', conversationId);

    socket.on('receiveMessage', (message) => {
      if (message.conversationId === conversationId) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    socket.on('userTyping', (data) => {
      if (data.conversationId === conversationId) {
        setIsTyping(true);
        setTimeout(() => setIsTyping(false), 3000);
      }
    });

    return () => {
      socket.emit('leaveConversation', conversationId);
      socket.off('receiveMessage');
      socket.off('userTyping');
    };
  }, [conversationId]);

  const sendMessage = async (content) => {
    const message = {
      conversationId,
      senderId: currentUser.id,
      content,
      timestamp: new Date().toISOString(),
    };

    try {
      // Save the message via the REST API
      await api.post(`/chat/v1/conversations/${conversationId}/messages`, message);

      // Emit the message via Socket.IO for real-time broadcasting
      socket.emit('sendMessage', message);

      // Update local state
      setMessages((prevMessages) => [...prevMessages, message]);
    } catch (error) {
      console.error('Error sending message:', error);
      // Optionally, display an error message to the user
    }
  };

  const handleTyping = () => {
    socket.emit('typing', { conversationId });
  };

  return (
    <ChatContainer>
      <MessageList messages={messages} currentUserId={currentUser.id} />
      {isTyping && <TypingIndicator />}
      <MessageInput onSendMessage={sendMessage} onTyping={handleTyping} />
    </ChatContainer>
  );
}

export default ChatWindow;
