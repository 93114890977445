// src/components/MessageInput.js
import React, { useState } from 'react';
import styled from 'styled-components';

const InputContainer = styled.form`
  display: flex;
  padding: 10px;
  /* Add styles */
`;

const TextInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  /* Add styles */
`;

const SendButton = styled.button`
  padding: 10px;
  /* Add styles */
`;

function MessageInput({ onSendMessage, onTyping }) {
  const [content, setContent] = useState('');

  const handleChange = (e) => {
    setContent(e.target.value);
    onTyping();
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (content.trim() === '') return;
    onSendMessage(content);
    setContent('');
  };

  return (
    <InputContainer onSubmit={handleSend}>
      <TextInput
        type="text"
        value={content}
        onChange={handleChange}
        placeholder="Type a message"
        aria-label="Message input field"
        required
      />
      <SendButton type="submit">Send</SendButton>
    </InputContainer>
  );
}

export default MessageInput;
