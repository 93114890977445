// src/components/ConversationItem.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ConversationContainer = styled.div`
  /* Add styles */
`;

function ConversationItem({ conversation, currentUserId }) {
  const { id, participants, lastMessage, unreadCount } = conversation;
  const otherUser = participants.find((p) => p.userId !== currentUserId);

  return (
    <Link to={`/chat/${id}`}>
      <ConversationContainer>
        <img src={otherUser.avatarUrl} alt={`${otherUser.displayName}'s avatar`} />
        <div>
          <h4>{otherUser.displayName}</h4>
          <p>{lastMessage.content}</p>
          {unreadCount > 0 && <span>{unreadCount}</span>}
        </div>
      </ConversationContainer>
    </Link>
  );
}

export default ConversationItem;
